.dialog-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;

  .dialog-header {
    border-bottom: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-content: flex-start;

    .dialog-title {
      font-size: 18px;
      color: #333;
      line-height: 1.33;
      font-weight: 600;
      margin-bottom: 0
    }

    .dialog-header-buttons {
      display: flex;

      & > * {
        margin: 0 0 0 11px;
      }
    }
  }

  .dialog-footer {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 30px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .dialog-footer-buttons-left {
      display: flex;

      & > * {
        margin: 0 10px 0 0;
      }
    }

    .dialog-footer-buttons-right {
      display: flex;

      & > * {
        margin: 0 0 0 10px;
      }
    }
  }
}
