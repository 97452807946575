@import "styles/resources";
.google-map {
  position: relative;
  background-color: #eee;
  overflow: hidden;

  img.marker {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -15px;

    -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  }
}
