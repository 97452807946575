// In app loading icon
.loading-icon {
  width: 40px;
  height: 40px;
  margin: 20px auto;
}

.panel-loading {
  height: 500px;
}

.reactivate-loading {
  height: 200px;
}

// Global loading icon for the app
.loading-app {
  left: 50%;
  width: 100px;
  height: 100px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
	position: absolute;

  svg {
    width: inherit;
    height: inherit;
  }

  .loader-segment {
    position: absolute;
    top: 0;
    left: 0;

    animation: spinner 1333ms infinite;
    animation-timing-function: cubic-bezier(0.720, 0.210, 0.365, 0.870);
  }
}

@keyframes spinner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(720deg);
  }
}
