@import "styles/resources";
.dev-panel {
  .tag a {
    color: #fff;
  }

  .dialog-kit-scroll {
    z-index: auto;
  }
}
.dev-panel-link button {
  border: 1px solid black;
  border-radius: 3px;
  background-color: transparent;
}

.branchPickers .form-group input {
  font-size: 16px !important;
}

.modal-dev-panel {
  @media (max-width: 767px) {
    min-height: 100vh;
    .branchPickers > .row > .col {
      flex-basis: auto;
    }
  }
}
