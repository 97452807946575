@import "styles/resources";
.login-pane {

  .forgot-password-btn {
    border: 1px solid $light_gray;
    &:hover {
      border-color: $light_gray !important;
    }
  }

  p {
    color: $white;
    font-size: 18px;
    line-height: 23px;
  }

  .form-control-feedback {
    color: $white !important;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    margin-top: -6px;
  }

  .has-danger {
    .form-control-feedback {
      color: $white !important;
      font-size: 14px;
      line-height: 18px;
      margin-top: 3px !important;
      margin-bottom: -5px !important;
    }
  }
}
