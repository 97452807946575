@import "styles/resources";
.reactivate {
  align-items: center;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  max-width: 1100px;
  overflow: hidden;

  @include media-breakpoint-down("sm") {
    align-items: unset;
    background-color: #ecf9e8;
    height: 100%;
  }

  .notice-list {
    &.page-layout {
      z-index: 100;
    }
  }

  .wiw-container {
    margin: 0;
    max-width: inherit;
    position: relative;
    z-index: 1;

    &::after {
      background-clip: content-box, padding-box;
      background-color: #ecf9e8;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      bottom: 0;
      content: " ";
      display: inline-block;
      height: 150px;
      position: absolute;
      width: 100%;
      z-index: 1;

      @include media-breakpoint-down("sm") {
        border-radius: 0;
        height: 12%;
      }
    }
  }

  .react-container {
    background-color: #fff;
    border-radius: 15px;
    margin: 0;
    padding: 20px;

    @include media-breakpoint-down("sm") {
      border-radius: 0;
      flex-direction: column;
      padding: 20px;
    }

    .header {
      h1 {
        color: #33213a;
        font-size: 44px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 24px;

        @include media-breakpoint-down("sm") {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 9px;
          margin-top: 18px;
        }
      }
    }

    .main-content {
      margin-left: 50px;
      margin-right: 14px;
      z-index: 2;

      @include media-breakpoint-down("sm") {
        margin: 0;
        padding: 0;

        img {
          width: 130px;
        }

        h1 {
          padding-left: 0;
        }
      }

      @include media-breakpoint-down("sm") {
        margin-top: 0;
        order: 1;
      }

      p {
        color: #33213a;
        font-size: 1rem;
        line-height: 20px;
      }

      .main-devices {
        @include media-breakpoint-down("sm") {
          display: none;
        }
      }

      #devicesImg {
        height: auto;
        margin-top: 50px;
        width: 100%;
        z-index: 2;
      }
    }

    .device-img-mobile {
      z-index: 2;

      @include media-breakpoint-down("sm") {
        order: 3;
      }

      >.row {
        margin: 0;
      }
    }

    .sidebar {
      background-color: #fff;
      border-radius: 5px;
      z-index: 2;

      @include media-breakpoint-down("sm") {
        order: 2;
      }

      @include media-breakpoint-up("md") {
        border: 1px solid $light_gray221;
        max-width: 330px;
      }

      .login-wrapper {
        background-color: $base_color;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 40px 40px 49px;

        @include media-breakpoint-down("sm") {
          border-radius: 5px;
          padding: 20px;
        }
      }

      .testimonial {
        padding: 50px;

        @include media-breakpoint-down("sm") {
          padding: 20px;
        }

        img {
          margin-bottom: 20px;
          max-height: 60px;
          max-width: 60px;
        }

        p,
        .attribution {
          font-size: 16px;
          font-style: italic;
          text-align: center;
        }

        .attribution {
          color: $mid_gray;
        }
      }
    }
  }

  .reactivate-content {
    h3 {
      color: $white;
      font-size: 24px;
      font-weight: 600;
    }

    h5 {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      word-break: break-all;
    }

    h6 {
      color: $white;
      font-size: 18px;
      font-weight: 450;
      padding-top: 10px;
    }

    .form-group {
      margin-bottom: 10px;

      .form-control-feedback {
        a {
          color: $white;
        }
      }
    }

    #email,
    #password {
      font-size: 1rem;
    }

    .arrow-left {
      color: #fff;
      height: 30px;
      padding-left: 0;
      padding-top: 0;
      text-transform: capitalize;
    }
  }
}
