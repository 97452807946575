.btn {
  border: none;
  border-radius: 3px;
  text-transform: uppercase;
  transition-duration: 100ms;
  line-height: 20px;
  font-family: inherit;
  font-weight: 600;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
  &:focus, &:active:focus {
    box-shadow: none !important;
  }
  &:disabled {
    cursor: not-allowed;
  }

  // Button colors
  &.btn-primary, &.btn-green {
    &:disabled {
      opacity: 1; // undoes bootstrap opacity
      background-color: $button_green_disabled;
    }

    &:not(:disabled) {
      &:hover {
        background-color: $button_green_hover;
      }

      &:active {
        background-color: $button_green_focus;
      }
    }
  }

  &.btn-warning, &.btn-orange {
    background-color: #c59a27;

    &:not(:disabled) {
      &:hover, &:active {
        background-color: #a77f01;
      }
    }
  }

  &.btn-danger, &.btn-red {
    background-color: #bc4f2e;

    &:not(:disabled) {
      &:hover, &:active {
        background-color: #a0341a;
      }
    }
  }

  &.btn-info, &.btn-blue {
    background-color: #558aaf;

    &:not(:disabled) {
      &:hover, &:active {
        background-color: #3b7095;
      }
    }
  }

  &.btn-gray {
    background-color: #999;

    &:not(:disabled) {
      &:hover, &:active {
        background-color: #7f7f7f;
      }
    }
  }

  &.btn-secondary, &.btn-outline {
    border: 1px solid #ddd;
    background-color: #fff;
    border-color: #ddd;
    color: #999;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    transition-duration: 50ms;

    &:not(:disabled) {
      &:hover, &:focus, &:active {
        color: #333;
      }
      &:active {
        background-color: #f2f2f2;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
      }
    }
  }

  // Button sizes
  &.btn-sm {
    padding: 5px 11px;
    font-size: 12px;
    line-height: 16px;

    &.btn-secondary, &.btn-outline {
      padding: 4px 10px;
    }

    &.btn-icon-only {
      padding: 0;
      width: 24px;
      height: 24px;
    }
  }

  &.btn-md {
    padding: 9px 13px;
    font-size: 14px;
    line-height: 18px;

    &.btn-secondary, &.btn-outline {
      padding: 8px 12px;
    }
  }

  &.btn-lg {
    padding: 10px 14px;
    font-size: 16px;
    line-height: 20px;

    &.btn-secondary, &.btn-outline {
      padding: 9px 13px;
    }
  }
}
