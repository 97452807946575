@import "styles/resources";
.account-logo {
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  outline: 1px solid #ddd;
  overflow: clip;
  overflow-clip-margin: inherit;
  width: 40px;
}
