.panel {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 15%);
  color: #555;
  margin-bottom: 30px;

  @media (max-width: $break-small) {
    border-radius: 0;
  }

  .panel-body {
    padding-bottom: 60px;
    padding-top: 60px;

    &>div {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media (max-width: $break-medium) {
      padding-bottom: 40px;
      padding-top: 40px;

      &>div {
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    @media (max-width: $break-small) {
      &>div {
        padding-left: $padding-small;
        padding-right: $padding-small;
      }
    }

    &.with-title {
      padding-top: 1.5rem;
    }

    &.no-vertical-spacing {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.no-gutters {
      &>div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.with-cityscape {
      padding-bottom: 0 !important;
    }

    .panel-title-row {
      margin-bottom: 30px;

      h1 {
        color: #333;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .panel-body-content {
      .introduction {
        text-align: center;

        p {
          font-size: 14px;
        }
      }
    }

    .cityscape {
      background-position: center;
      background-size: cover;
      height: 120px;
      margin-top: 30px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.panel-list {
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
}

.panel-list-item {
  align-items: stretch;
  background: white;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  min-height: 70px;
  transition: background-color 50ms ease-in-out;

  :first-child {
    border-top-width: 0;
  }

  .column {
    align-items: center;
    display: flex;
    padding: 0 10px;
  }

  &:hover {
    background-color: #fafafa;

    .row-action {
      color: #666;
    }
  }

  &:active {
    background-color: #f6f6f6;
  }

  &:last-child {
    border-bottom: 1px solid #ddd;
  }

  .row-action {
    color: #aaa;
    padding: 5px;
    text-decoration: none !important;
    transition: color 0.2s ease-in-out;

    i {
      display: block;
      font-size: 16px;
    }

    &:hover {
      color: #222;
    }
  }

  &.dragging {
    background-color: hsl(0deg 0% 100% / 80%);
    border-width: 0;
    box-shadow: 0 5px 5px -5px rgb(0 0 0 / 20%), 0 -5px 5px -5px rgb(0 0 0 / 20%);
    cursor: row-resize;
  }
}
