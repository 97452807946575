@import "styles/resources";
.dialog-kit-scroll .dialog-kit.step-dialog {
  .step-list {
    border-right: 1px solid #ddd;

    .step-list-inner {
      padding: 25px 30px;
    }

    h1 {
      color: #262626;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-bottom: 25px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        color: #666;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;

        button {
          background: 0;
          border: 0;
          padding: 0;

          &[disabled] {
            cursor: default;
          }
        }

        i {
          background-color: #d8d8d8;
          border-radius: 12px;
          display: inline-block;
          font-size: 14px;
          font-style: normal;
          height: 24px;
          line-height: 24px;
          margin-right: 8px;
          text-align: center;
          width: 24px;
        }

        &.current {
          button {
            color: #333;
          }

          i {
            background-color: #51a33d;
            color: #fff;
          }
        }

        &.complete {
          i {
            background-color: #c9f9bb;
            color: #51a33d;
            font-size: 14px;

            span {
              display: none;
            }
          }

          .locked {
            background-color: #eee;
            color: #999;
          }
        }
      }
    }
  }

  .step-dialog-body {
    .dialog-header {
      padding-bottom: 12px;

      .dialog-button.button-close {
        margin-top: 6px;
      }

      h1 {
        font-size: 30px;
        font-weight: 600;
      }
    }

    .dialog-body {
      flex: 1 1 0;

      .row+.row {
        margin-top: 14px;
      }

      p {
        font-weight: normal;
        margin-bottom: 20px;
      }

      .form-group {
        .form-control-label {
          small {
            float: right;
            font-size: 12px;
            font-weight: normal;
            text-transform: none;
          }
        }
      }
    }
  }
}
