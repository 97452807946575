@import "styles/resources";
body:has(#wiw-fullscreen) {
  background-color: $white;
}

#wiw-fullscreen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $light_gray221;
    height: 80px;
  }

  > main {
    position: relative; // puts the loading indicator in the middle of the content section
    display: flex;
    flex: 1;
  }

    @media (max-width: $break-sm-max) {
    > header {
      height: 58px;
    }

    .wiw-logo {
      scale: 80%;
    }
  }

  .wiw-fullscreen-error {
    margin: 4.5rem auto 0;
    padding: 0 1rem;

    h1 {
      color: $dark-gray;
      font-size: 1.875rem;
      line-height: calc(38 / 30);
      font-weight: 600;
      text-align: center;
      margin-bottom: 4rem;
    }

    h6 {
      font-size: 1rem;
      line-height: 1.5;
      color: $light_mid_gray;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    @media (max-width: $break-medium) {
      margin: 2rem auto 0;

      h1 {
        margin-bottom: 1.875rem;
      }

      h6 {
        margin-bottom: 1rem;
      }
    }
  }
}
