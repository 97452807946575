@import "styles/resources";
.browser-gate {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 15px;
  margin-top: 30px;

  .logo {
    margin-top: 20px;
    position: relative;
    height: 50px;
    margin-bottom: 40px;

    .logo-lines {
      position: absolute;
      top: 26px;
      left: 0;
      right: 0;
      height: 2px;
      border-bottom: 1px solid #b2b3b3;
      box-shadow: 0 1px 0 #ffffff;
    }

    img {
      position: relative;
      z-index: 2;
      height: 50px;
      background-color: #eeeeee;
      padding: 0 20px;
    }
  }

  .panel-title {
    text-align: center;
    border-bottom: 1px solid #dddddd;
    line-height: 42px;
    font-size: 30px;
    font-weight: 600;
    color: #333;
    padding: 20px 30px 22px;
  }

  .browser-logo {
    width: 80px;
    height: 80px;
  }

  .browser-title {
    color: #51a33d;
    font-weight: bold;
  }

  .browser-version {
    font-size: 14px;
    color: #666;
    font-weight: normal;
  }
}
