@import "styles/resources";
@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast-container {
  z-index: $zindex-page-notice;
  pointer-events: none;

  @media only screen and (max-width: 480px) {
    width: 96vw;
    margin-left: 2vw;
  }

  &.Toastify__toast-container--top-right {
    top: 5px;
    transition: top 0.5s ease 0s;
    &:not(.toast-sticky) {
      top: 60px;
    }
  }
}

@keyframes toast-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}


.Toastify__toast {
  pointer-events: auto;

  &.toast-updated {
    animation: toast-shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
  }

  font-family: $font-family-sans-serif;
  min-height: auto;
  border-radius: 5px;
  padding: 0px;
  margin-bottom: 0.5rem;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.no-dismiss {
    cursor: default;
  }

  .Toastify__toast-body {
    overflow-wrap: break-word;
    word-wrap: break-word;
    min-width: 70%;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    a {
      color: #fff;
      text-decoration: underline;
    }
    p {
      margin-bottom: 5px;
    }
  }
  &:before {
    content: ' ';
    width: 34px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: 10px 10px;
  }
  &.Toastify__toast--info {
    background: #000;
    &:before {
      display: none;
    }
  }
  &.Toastify__toast--success {
    &:before {
      background-color: #51a33d;
      background-image: url('wiw/notices/assets/img/success@2x.png');
    }
    background: #000;
  }
  &.Toastify__toast--warning {
    background: #000;
    &:before {
      background-color: #C59A27;
      background-image: url('wiw/notices/assets/img/warn@2x.png');
    }
  }
  &.Toastify__toast--error {
    background: #000;
    &:before {
      background-color: #D4364C;
      background-image: url('wiw/notices/assets/img/error@2x.png');
    }
  }

  button.action-close-toast {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;

    &:hover, &:focus {
      opacity: 1;
    }

    .close-toast {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-28;

      // 34px is the height of a one line toast
      height: 34px;
      width: 34px;
    }
  }
}
