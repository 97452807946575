.panel-body {
  h1 {
    text-align: center;
    color: #666;
    font-weight: 600;
    font-size: 2rem;
    margin: 1.5rem 0;
  }
}

.input-group {
  .input-group-prepend+.Select .Select__control {
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.Select__fieldset .input-group:hover .input-group-text {
  border-color: $brand-primary !important;
}

.Select__fieldset .input-group-text {
  transition: color 100ms ease-in-out, border-color 100ms ease-in-out;
}

.input-group-prepend .input-group-text {
  padding-right: 0;
}

.form-group {
  label {
    color: #555555;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  small {
    font-size: 13px;
  }

  .form-control,
  .input-group,
  .input-group-prepend,
  .input-group-append,
  label {
    transition: color 100ms ease-in-out, border-color 100ms ease-in-out;
  }

  .form-control {
    border-radius: 2px;
    background-image: none !important;
    //This change will keep iphones from auto zooming on focus
    @media (max-width: 767px) {
      font-size: 16px;
    }
    // remove border right if there is an appended child to an input
    &:not(:last-child) {
      border-right: none !important;
    }
  }

  .input-group-append {
    border-right: 1px solid $border-color !important;
  }


  .input-group-text {
    background-color: transparent;
    border-color: #ccc !important;
    border-right: none !important;
    transition: color 100ms ease-in-out, border-color 100ms ease-in-out;

    .wiw-icon {
      color: $gray;
      height: 14px;
    }
  }

  .input-group .form-control {
    border-left: 0;
  }

  .form-control:focus,
  .input-group:focus,
  .form-control.has-focus:not(.is-invalid),
  .form-control.is-valid,
  .form-group.focus .form-control.rich-text-area {
    box-shadow: none;
    border-color: $base-color;
  }

  &.focus {

    .input-group-prepend,
    .input-group-append,
    .input-group-text {
      border-color: $base_color !important;
    }

    input,
    textarea {
      border-color: $base-color !important;
    }
  }

  .Select {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
  }

  &.is-invalid {

    .form-control,
    .input-group,
    .input-group-prepend,
    .input-group-append,
    .form-control-feedback,
    input,
    label,
    .input-group-text {
      border-color: $brand-danger !important;
      color: $brand-danger;
    }

    .input-group-danger {
      opacity: 1;
    }

    .Select__control {
      border-color: $brand-danger !important;
      color: $brand-danger;

      .Select__placeholder {
        color: lighten($brand-danger, 15%);
      }
    }
  }

  .form-control-label {
    line-height: 14px;
  }

  .form-control-feedback {
    font-size: 13px;
    line-height: 19.5px;
    margin-top: 4px;
  }

  &.custom-checkbox {

    &.is-invalid {
      a {
        color: $brand-danger;
      }
    }

    .form-control-feedback {
      margin-top: -4px;
    }
  }
}

.merged-form {
  .input-group-addon i {
    width: 16px;
  }

  .input-group:not(:last-child) {

    .input-group-addon,
    .form-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group:not(:first-child) {
    margin-top: -1px;

    .input-group-addon,
    .form-control {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.input-group-addon i {
  min-width: 16px;
}

.form-group-icon {
  position: relative;

  .form-control-lg {
    padding-left: 2.5rem;
  }

  >i {
    pointer-events: none;
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    background: none;
    color: #999;
    padding: 0 1rem;
    top: 0;

    &.icon-lg {
      font-size: $font-size-lg;
    }

    &.pull-right {
      right: 0;
    }
  }
}

.form-divider {
  display: block;
  text-align: center;
  margin: 20px auto 29px;
  font-size: 12px;
  font-weight: 600;

  >span {
    background: white;
    padding: 0 10px;
    display: inline-block;
    text-transform: uppercase;
    color: #757575;
  }

  >hr {
    height: 1px;
    background: #dddddd;
    border: 0;
    margin: -9px 0 0;
    width: 100%;
  }
}

.Select {
  .Select-control {
    height: 41px;
    border: none;

    &:hover {
      box-shadow: none;
    }
  }

  .Select-placeholder {
    line-height: 41px;
  }

  .Select-value-label {
    color: #666 !important;
    line-height: 38px;
  }

  &.is-focused {

    .Select-control {
      box-shadow: none;
      border-color: #ccc;
    }
  }

  .Select-input {
    height: 38px;

    >input {
      color: #666;
      height: 20px;
      padding: 9px 0 11px;
    }
  }
}

.Select {
  font-size: 13px;
  outline-color: $brand-primary !important;

  &.is-focused {
    @extend .form-control, :focus;
  }

  &__single-value {
    color: $dark_gray !important;
  }

  .Select__option {
    cursor: pointer;
  }

  .Select__option + .Select__option {
    border-top: 1px solid $light_gray221;
  }

  &__option {
    color: $dark_gray !important;

    &--is-selected {
      background-color: transparent !important;
    }

    &--is-focused,
    &:active {
      background-color: $light_gray221 !important;

      .Select-control {
        box-shadow: none;
        border-color: #ccc;
      }
    }
  }

  &__option--is-disabled {
    color: $light_gray221 !important;
  }

  &:not(.Select--multi).Select-value {
    max-width: calc(100% - 30px) !important;
  }

  &__menu {
    border-radius: 2px !important;

    &-list .Select__option--is-focused {
      background-color: $light_green !important;
    }
  }

  &__control {
    min-height: 42px;
    border-radius: 2px !important;
    border: 1px solid #ccc;
    background-color: #ffffff;

    &:hover {
      box-shadow: none !important;
      border-color: $brand-primary !important;
    }

    &--is-focused {
      box-shadow: none !important;
      border-color: $brand-primary !important;
    }

    &--is-disabled {
      background-color: #f9f9f9 !important;
      color: $gray;
    }

    &__menu {
      margin-top: 0 !important;
      z-index: 4 !important;
      border-radius: 2px !important;

      &-list {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .Select__clear-indicator {
        opacity: 0;
        cursor: pointer;
      }

      &.Select__control--is-focused .Select__clear-indicator {
        opacity: 1;
      }

      &:hover {
        .Select__clear-indicator {
          opacity: 1;
        }
      }
    }
  }

  &__indicators {

    .Select__indicator-separator {
      background-color: #ccc;
      margin-top: 0;
      margin-bottom: 0;
    }

    .Select__dropdown-indicator {
      font-family: wiw-icons;
      color: $gray;
      padding: 0 12px;

      &:before {
        content: "\f121";
      }

      svg {
        display: none;
      }
    }
  }

  &__group {
    padding-top: 0;

    .Select__group-heading {
      padding-top: 8px;
      font-weight: 600;
      font-size: 13px;
      color: #555555;
      text-transform: none;
      border-bottom: 1px solid #dddddd;

      &--is-focused,
      &:active,
      &:hover {
        background-color: $light_green !important;

        .Select-control {
          box-shadow: none;
          border-color: #ccc;
        }
      }
    }
  }
}

.optgroup-parent {
  background-color: #fafafa;
  color: $dark_gray;
  cursor: pointer;
  font-weight: bold;
}


.Select__value-container--is-multi {

  .Select__multi-value {
    background-color: $light_gray245 !important;
    border: 1px solid $light_gray221;

    .Select__multi-value__remove {
      cursor: pointer;
    }

    .Select__multi-value__label,
    .Select__multi-value__remove {
      background-color: $light_gray245 !important;
      color: $dark_gray;
      font-size: 1em;

      svg {
        color: $gray;
      }
    }
  }
}

.dropdown-options {
  position: absolute;
  left: -1px;
  right: -1px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ddd;
  user-select: none;

  max-height: 220px;
  overflow-y: scroll;
  border-radius: 0 0 2px 2px;

  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15), 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}

// Custom select with optgroups
.select-container {
  border: 1px solid $light_gray;

  .selected {
    padding: 10px 40px;
    cursor: default;
  }

  &.placeholder .selected {
    opacity: 0.75;
  }

  .chevron {
    padding: 10px 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    svg {
      width: 16px;
    }
  }

  .options {
    @extend .dropdown-options;

    .option {
      border-bottom: 1px solid #ddd;
      padding: 10px;
      padding-left: 15px;
      line-height: 18px;
      cursor: default;
      transition: background-color 50ms ease-in-out;

      &:hover {
        background-color: #f9f9f9;
      }

      &:active {
        background-color: #f5f5f5;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .optgroup {
      border-bottom: 1px solid #ddd;

      .option:first-child {
        font-weight: 600;
      }

      .option:not(:first-child) {
        padding-left: 25px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.geosuggest {
  position: relative;

  .input-group {
    overflow: visible;

    input[type="text"].form-control {
      border-right: 1px solid $input-border-color !important;

      &:focus {
        border-color: $primary !important;
      }

      &.is-invalid {
        border-color: $brand-danger !important;
      }
    }
  }



  .geo-list {
    @extend .dropdown-options;
    white-space: nowrap;
    top: 38px;

    a {
      display: block;
      padding: 10px 20px;
      cursor: pointer;

      &:not(:first-child) {
        border-top: 1px solid #ddd;
      }

      .title {
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .address {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #757575;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.notice-list {
  .notice {
    position: relative;
    padding: 7px 38px 7px 12px;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    border: none;
    border-radius: 6px;

    &.alert {
      background-color: #d0404b;
    }

    button.close {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto;

      color: #fff;
      opacity: 1;

      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }
}
