@import "styles/resources";
.phone-input.form-group {
  .input-group {
    overflow: visible;
  }

  .phone-input-wrapper {
    display: flex;

    .Select {
      flex-basis: 60px;
      z-index: 100;
    }

    .Select__control {
      border: none;
      height: 100%;
      background-color: #fff;
    }

    .Select__dropdown-indicator {
      padding-left: 0;
      padding-right: 4px;
    }

    .Select__single-value {
      width: 18px;
    }

    .flag-icon {
      width: 18px;
    }

    .Select__single-value .flag-icon {
      transform: translateY(-0.5px);
    }

    .option-label {
      margin-left: 10px;
      margin-right: 4px;
    }

    .option-code {
      color: #999;
    }

    .form-control {
      padding-left: 55px;
      padding-top: 0.72rem;
      padding-bottom: 0.72rem;
    }
  }
}
