// Add global stuffs here

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;

  font-family: 'Source Sans Pro', sans-serif;
  background: #eee;
  svg {
    height: 13px;
    fill: #999999;
  }

  strong {
    font-weight: 600;
  }

  .column {
    &.name {
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      span {
        color: #757575;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 12px;
      }
      strong {
        color: #555555;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    &.icon {
      padding-left: 20px;
    }
  }
}

.panel-no-header {
  padding-top: 40px;

  @media (max-width: $break-small) {
    padding-top: 30px;
  }
}

.content--inside {
  margin-top: 80px;
  position: relative;

  @media (max-width: $break-small) {
    margin-top: 0;
  }
}
