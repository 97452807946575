@import "styles/resources";
@import "../../../styles/variables";

.small-header {
  $paddingVert: 20px;
  $paddingHorz: 30px;

  padding: $paddingVert $paddingHorz !important;
  border-bottom: 1px solid #e5e5e5;
  position: relative;

  .wiw-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  button.btn {
    height: 30px;
    padding: 5px 0;

    position: absolute;
    margin-top: 0;
    top: $paddingVert;
    cursor: pointer;
    transition: color 50ms ease-in-out;
    text-transform: none;

    &:hover {
      background: none;
    }

    &:active {
      color: #555 !important;
    }

    &.back {
      left: $paddingHorz;
      color: #238500;
      .wiw-arrow-left {
        width: 14px;
        height: auto;
        padding-bottom: 2px;
        position: relative;
        top: 3px;
      }

      @media (max-width: $break-small) {
        left: $padding-small !important;
      }
    }
    &.logout {
      right: $paddingHorz;
      color: #666;
      .wiw-power-on-off {
        margin-right: 5px;
        position: relative;
        top: 3px;
      }
      @media (max-width: $break-small) {
        right: $padding-small !important;
      }
    }
    .wiw-arrow-left  {
      margin-right: 5px;
      vertical-align: baseline;
    }
  }
}
