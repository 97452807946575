@import "styles/resources";
.footer {
  margin-top: 50px;
  margin-bottom: 30px;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .support {
    font-size: 16px;
    color: #757575;
    margin-top: 22px;
    margin-bottom: 15px;

    a {
      font-weight: 600;
    }
  }

  .links {
    font-size: 12px;

    a {
      color: #666;
      font-weight: 600;
    }

    .divider {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
