/**
 * As of 2/9/24 I'm not really sure this file is being used anywhere, but I'm too afraid to delete it.
 */

.btn {
  &.btn-link {
    border: none;
    box-shadow: none;
    background-color: transparent !important;
  }

  .loader-inner.ellipsis {
    font-size: 36px;

    @keyframes EllipsisBounce {
      0%, 60% {
        transform: translateY(-5px);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      30% {
        transform: translateY(-12px);
        animation-timing-function: cubic-bezier(0.8, 0, 0.6, 1);
      }
    }

    span {
      display: inline-block;
      transform: translateY(-5px);
      animation: EllipsisBounce 1.75s infinite;

      &:nth-child(2) {
        animation-delay: 0.15s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
    }
  }

}

.two-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .btn {
    font-size: 14px;
    width: 47.5%;
    margin: 0;
  }

  @media (max-width: $break-medium) {
    .btn {
      width: 100%;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}

.btn-img {
  height: 1em;
  margin-right: 10px;
}

.btn-contents {
  vertical-align: middle;
}

.btn-normalcase {
  text-transform: none;
}

.panel-title .btn-back {
  margin-top: 0;
  padding: 0.5rem;
  line-height: 1;

  > i {
    min-width: 16px;
  }
}

.btn-gold {
  background-color: #FFCC33;
  color: $dark-gray;
  border-width: 0 !important;

  &:disabled {
    background-color: lighten(#FFCC33, 10);
  }

  &:hover {
    background-color: darken(#FFCC33, 10);
  }
}
