@import "styles/resources";
.background-dialog {
  .notice-list {
    &.dialog-layout {
     display: none;
    }
  }
}
.notice-list {

  &.page-layout {
    margin-top: -20px;
    z-index: 1;
    min-height: 20px;
    padding-bottom: 20px;
    pointer-events: none;
  }

  &.dialog-layout {
    position: relative;

    .notice-wrapper {
      margin: 0;
      border-radius: 4px 4px 0 0;
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .notice-wrapper {
    overflow: hidden;
    transition: 500ms ease-in-out, opacity 500ms ease-in-out;
    opacity: 0;

    &.notice-is-entering, &.notice-is-entered {
      opacity: 1;
    }
  }

  span:first-child {
    display: inherit !important;
  }

  .notice {
    border-top-color: transparent;
    border-radius: 0 !important;
    border-width: 0;
    color: #ffffff;
    padding: 0 !important;
    margin-bottom: 5px;
    width: 100%;
    background: #000;
    display: flex;
    justify-content: space-between;

    &::before {
      content: ' ';
      width: 34px;
      background: #000 no-repeat 10px 10px;
      background-size: 14px 14px;
    }

    &.alert-success::before {
      background-color: #51a33d;
      background-image: url('wiw/notices/assets/img/success@2x.png');
    }

    &.alert-warning::before {
      background-color: #C59A27;
      background-image: url('wiw/notices/assets/img/warn@2x.png');
    }

    &.alert-danger::before {
      background-color: #D4364C;
      background-image: url('wiw/notices/assets/img/error@2x.png');
    }

    &.alert-info::before {
      display: none;
    }

    .notice-content {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: 8px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      min-width: 70%;
      flex: 1;

      a {
        color: #fff;
        text-decoration: underline;
      }
      p {
        margin-bottom: 5px;
      }
      h1, h2, h3, h4, h5 {
        color: #fff;
      }
    }

    &.hidden {
      position: absolute;
      visibility: hidden;
    }

    button.close-notice {
      color: #fff;
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s ease;
      align-self: flex-start;

      &:hover, &:focus {
        opacity: 1;
      }

      .close-notice {
        display: block;
        font-size: 28px;
        width: 28px;
        height: 34px;
      }
    }
  }
}
