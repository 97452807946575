@import "styles/resources";
.error-boundary {
  padding-top: 0 !important;
  font-family: "Source Sans Pro", sans-serif;

  .error-button {
    margin-bottom: 40px;
  }

  .error-text {
    color: $mid_gray;
    font-size: 16px;
    text-align: center;
  }

  .error-title {
    color: $dark_gray;
    font-size: 30px;
    padding-bottom: 40px;
  }
}
